import "./App.css";
import { ReactComponent as Download } from "./assets/download.svg";
import { ReactComponent as Play } from "./assets/play.svg";
import { ReactComponent as Pause } from "./assets/pause.svg";
import { FaPlay, FaPause, FaForward, FaBackward } from "react-icons/fa";
import { BsFillSkipForwardFill, BsSkipBackwardFill } from "react-icons/bs";

function Player({
  title,
  onNext,
  onPrevious,
  isPlaying,
  onPlay,
  onPause,
  onDownload,
}) {
  return (
    <div className="player">
      <div className="player-buttons">
        <div className="player-play-button" onClick={onPrevious}>
          <FaBackward color="#fff" style={{ width: "24px", height: "24px" }} />
        </div>
        <div
          className="player-play-button"
          onClick={() => {
            if (isPlaying) {
              onPause();
            } else {
              onPlay();
            }
          }}
        >
          {!isPlaying ? (
            <FaPlay color="#fff" style={{ width: "24px", height: "24px" }} />
          ) : (
            <FaPause color="#fff" style={{ width: "24px", height: "24px" }} />
          )}
        </div>
        <div className="player-play-button" onClick={onNext}>
          <FaForward color="#fff" style={{ width: "24px", height: "24px" }} />
        </div>

        <p className="player-title">{title}</p>
        <div className="player-download-button" onClick={onDownload}>
          <Download color="#fff" style={{ width: "24px", height: "24px" }} />
        </div>
      </div>
    </div>
  );
}

export default Player;
