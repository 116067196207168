import axios from "axios";
// import { https } from "https-browserify";
import https from "https";

const back = "https://muslum-magomayev.az/api";

const agent = new https.Agent({
  rejectUnauthorized: false,
});

export function getTitles() {
  let link = `${back}/api/titles?sort=id`;

  // const httpsAgent = new https.Agent({ rejectUnauthorized: false });

  // const agent = new https.Agent({
  //   rejectUnauthorized: false,
  // });

  var myHeaders = {
    "Content-Type": "application/json",
  };

  return axios.get(link, { httpsAgent: agent });
}
