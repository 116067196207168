import { ReactComponent as Download } from "./assets/download.svg";
import { ReactComponent as Play } from "./assets/play.svg";
import { ReactComponent as Pause } from "./assets/pause.svg";

import "./App.css";

function SongTitle({
  title,
  duration,
  isPlaying,
  onDownload,
  onPlay,
  onPause,
}) {
  return (
    <div className="song-title-holder">
      <div
        className="button-round song-button"
        onClick={() => {
          if (isPlaying) {
            onPause();
          } else {
            onPlay();
          }
        }}
      >
        {!isPlaying ? (
          <Play color="#ccc9c5" style={{ width: "28px", height: "28px" }} />
        ) : (
          <Pause color="#ccc9c5" style={{ width: "28px", height: "28px" }} />
        )}
      </div>
      <div className="button-round song-button" onClick={onDownload}>
        <Download color="#ccc9c5" style={{ width: "24px", height: "24px" }} />
      </div>
      <p className="song-title">{title}</p>
      <p className="song-duration">{duration}</p>
    </div>
  );
}

export default SongTitle;
