import image from "./assets/magomayev.jpeg";
import { ReactComponent as Heart } from "./assets/heart.svg";
import SongTitle from "./SongTitle";
import { useState, useEffect, useRef } from "react";
import "./App.css";
import Player from "./Player";
import { getTitles } from "./actions";
import { useTranslation } from "react-i18next";

import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

function App() {
  const [isPlaying, setPlaying] = useState(false);
  const [currentSong, setSong] = useState(0);
  const [playlist, setPlaylist] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [menuLanguage, setMenuLanguage] = useState(true);
  // const [audio, setAudio] = useState(new Audio(playlist[1].link));
  const audioElement = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getTitles().then((res) => {
      setPlaylist(res.data);
    });
  }, []);

  const SkipSong = (forward = true) => {
    let tmp = currentSong;
    if (forward) {
      if (currentSong === playlist.length - 1) {
        tmp = 0;
      } else {
        tmp++;
      }
    } else {
      if (currentSong === 0) {
        tmp = playlist.length - 1;
      } else {
        tmp--;
      }
    }
    setCurrentSong(tmp);
  };

  const setCurrentSong = (index) => {
    setSong(index);
    setIsPlaying(true);
    setTimeout(() => {
      setIsPlaying(false);
      setIsPlaying(true);
    }, 100);
  };

  const setIsPlaying = (state) => {
    if (state) {
      audioElement.current.play();
    } else {
      audioElement.current.pause();
    }
    setPlaying(state);
  };

  return (
    <div className="App">
      <div className="language-selector">
        <div class="switch">
          <input
            id="language-toggle"
            class="check-toggle check-toggle-round-flat"
            type="checkbox"
            onClick={(value) => {
              console.log(value.target.value);
              i18n.changeLanguage(!menuLanguage ? "en" : "az");
              setMenuLanguage(!menuLanguage);
            }}
          />
          <label for="language-toggle"></label>
          <span class="on">EN</span>
          <span class="off">AZ</span>
        </div>
      </div>
      <header className="header-holder">
        <img src={image} className="header-image" alt="Muslim Magomayev" />
        <div className="header-body">
          <p className="body-title">{t("name")}</p>
          <div
            className="body-description"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: t("description") }}
          ></div>
          <div className="buttons-holder">
            <p
              href="#"
              className="button-primary"
              onClick={() => {
                setIsPlaying(!isPlaying);
              }}
            >
              {isPlaying ? t("pause") : t("listen")}
            </p>
            {/* <div
              href="#"
              className="button-round"
              onClick={() => setIsLiked(!isLiked)}
            >
              {isLiked ? (
                <AiFillHeart
                  color="#ccc9c5"
                  style={{ width: "24px", height: "24px" }}
                />
              ) : (
                <AiOutlineHeart
                  color="#ccc9c5"
                  style={{ width: "24px", height: "24px" }}
                />
              )}
            </div> */}
          </div>
        </div>
      </header>
      <div className="content-body">
        <p className="body-subtitle">{t("songs")}</p>
        {playlist.map((song, index) => (
          <SongTitle
            title={menuLanguage ? song.nameEn : song.name}
            duration={song.duration}
            onDownload={() => {
              window.open(song.link);
            }}
            isPlaying={currentSong === index && isPlaying}
            onPlay={() => {
              if (currentSong !== index) {
                setCurrentSong(index);
              } else {
                setIsPlaying(true);
              }
            }}
            onPause={() => {
              setIsPlaying(false);
            }}
          />
        ))}
      </div>

      {playlist[currentSong] && (
        <>
          <audio
            id="player"
            src={playlist[currentSong].link}
            ref={audioElement}
          ></audio>
          <Player
            onPlay={() => {
              setIsPlaying(true);
            }}
            onPause={() => {
              setIsPlaying(false);
            }}
            isPlaying={isPlaying}
            onNext={() => {
              SkipSong(true);
            }}
            onPrevious={() => {
              SkipSong(false);
            }}
            onDownload={() => {
              window.open(playlist[currentSong].link);
            }}
            title={
              menuLanguage
                ? playlist[currentSong].nameEn
                : playlist[currentSong].name
            }
            src={playlist[currentSong].link}
          />
        </>
      )}

      <div className="footer" />
    </div>
  );
}

export default App;
